<template>
  <v-flex>
    <v-subheader>
      {{ label }}
      <span class="secondary--text">*</span>
    </v-subheader>
    <v-autocomplete
      rounded
      filled
      :items="organizations"
      v-model="select"
      :loading="loading || debouncing"
      :search-input.sync="search"
      no-filter
      item-value="code"
      auto-select-first
      :disabled="disabled"
      :readonly="readOnly"
      clearable
      :rules="rules"
      dense
    >
      <template v-slot:append v-if="hint">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="grey lighten-1">
              mdi-help-circle-outline
            </v-icon>
          </template>
          {{ hint }}
        </v-tooltip>
      </template>
      <template slot="selection" slot-scope="data">
        {{ getNameAndCodeAndManager(data.item) }}
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.code }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="data.item.ownedByEmployee">
            {{ data.item.ownedByEmployee.firstName }} {{ data.item.ownedByEmployee.lastName }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-flex>
</template>

<script>
import { useOrganizationStore } from '@/store/organization'
import { mapActions } from 'pinia'
import { mapState } from 'pinia'
import AccountMixin from '@/mixins/account.mixin'

export default {
  props: {
    applicant: { type: Object },
    label: { type: String, default: 'Organization' },
    multiple: { type: Boolean, default: false },
    selectedOrganization: { type: String },
    disabled: { type: Boolean },
    readOnly: { type: Boolean },
    sponsorOrganizations: { type: Array },
    hint: { type: String },
    rules: { type: Array }
  },
  mixins: [ AccountMixin ],
  data() {
    return {
      select: null,
      options: {
        page: 1,
        itemsPerPage: 1,
        query: null
      },
      search: '',
      loading: false,
      debouncing: false,
      debounceTimeout: null,
      selectedSponsorOrgManagerEmployeeNumber: null
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    },
    ...mapState(useOrganizationStore, ['organization', 'organizations'])
  },
  methods: {
    getNameAndCodeAndManager(item) {
      if (item.ownedByEmployee) {
        this.selectedSponsorOrgManagerEmployeeNumber = item.ownedByEmployee.employeeNumber
        this.$emit('updateSelectedSponsorOrgManagerEmployeeNumber', this.selectedSponsorOrgManagerEmployeeNumber)
        return `${item.name} (${_.trim(item.code)}) [${_.trim(item.ownedByEmployee.firstName)} ${_.trim(item.ownedByEmployee.lastName)}]`
      }
      else {
        this.selectedSponsorOrgManagerEmployeeNumber = null
        this.$emit('updateSelectedSponsorOrgManagerEmployeeNumber', this.selectedSponsorOrgManagerEmployeeNumber)
        return `${item.name} (${_.trim(item.code)})`
      }
    },
    async updateSelectedOrganization() {
      if (!_.isEmpty(this.selectedOrganization)) {
        this.search = this.selectedOrganization
        this.select = this.selectedOrganization
        this.options.query = this.search
        await this.searchOrganizationsAsync(this.search).then((res) => {})
      }
    },
    async querySelections(val) {
      this.loading = true
      let requestType = this.requestType
      if (this.disabled) {
        requestType = ''
      }
      await this.searchOrganizationsAsync(val, requestType)
        .then((res) => {
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    ...mapActions(useOrganizationStore, ['searchOrganizationsAsync'])
  },
  watch: {
    async selectedOrganization() {
      await this.updateSelectedOrganization()
    },
    search(val) {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debouncing = false
          this.querySelections(val)
        }, 300)
      }
    },
    select(o) {
      if (o !== null && o !== undefined) {
        this.$emit('update:keyword', o)
        this.$emit('update:selectedOrganization', o)
        this.$emit('updateSelectedSponsorOrgCode', o)
        this.$emit('updateSelectedSponsorOrgManagerEmployeeNumber', this.selectedSponsorOrgManagerEmployeeNumber)
      } else {
        this.$emit('update:keyword', null)
        this.$emit('update:selectedOrganization', null)
        this.$emit('updateSelectedSponsorOrgCode', null)
        this.$emit('updateSelectedSponsorOrgManagerEmployeeNumber', null)
      }
    }
  },
  async mounted() {
    await this.updateSelectedOrganization()
  }
}
</script>