import { defineStore } from 'pinia'
import ApiService from '@/service/api.service.js'

export const useServiceStore = defineStore('service', {
  state: () => ({
    service: null,
    services: [],
    serviceSnackbar: {
      text: null,
      color: null,
      value: false
    },
    serviceStatus: null
  }),
  getters: {
    getService(state) {
      return state.service
    },
    getServices(state) {
      return state.services
    },
    getServiceStatus(state) {
      return state.serviceStatus
    }
  },
  actions: {
    async sendServiceActivationLinkAsync(serviceId, username) {
      return ApiService.customRequest({
        method: 'post',
        url: `/Services/${serviceId}/Accounts/${username}/Activate`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          this.serviceSnackbar = {
            text: 'Service activation email sent.',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.serviceSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.serviceSnackbar = {
              text: `${error.response.data}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async getUserServiceStatusAsync(username, serviceId) {
      return ApiService.customRequest({
        method: 'get',
        url: `/Services/${serviceId}/Accounts/${username}/Status`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          return res.data
        })
    },
    async unsubscribeFromServiceAsync(serviceId, accountId) {
      return ApiService.customRequest({
        method: 'put',
        url: `/Services/${serviceId}/Accounts/${accountId}/Unsubscribe`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          this.serviceSnackbar = {
            text: 'Guest account unsubscribed from service',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.serviceSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.serviceSnackbar = {
              text: `${error.response.data}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async fetchServicesAsync() {
      return ApiService.customRequest({
        method: 'get',
        url: `/Services`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.services = result.data
          return {
            success: true,
            data: result.data
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.message}. ${error.response.data}`,
              text: `${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
          return {
            success: false,
            error
          }
        })
    }
  }
})