import ApiService from '@/service/api.service.js'
import { defineStore } from 'pinia'

export const useOrganizationStore = defineStore('organization', {
  state: () => ({
    organization: null,
    organizations: null
  }),
  getters: {
    getOrganization(state) {
      return state.organization
    },
    getOrganizations(state) {
      return state.organizations
    }
  },
  actions: {
    async searchOrganizationsAsync(q) {
      ApiService.customRequest({
        method: 'get',
        url: `/Search/Organizations/${q}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.organizations = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchOrganizationAsync(code) {
      ApiService.customRequest({
        method: 'get',
        url: `/Organization/${code}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.organization = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
})
