import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.label)+" "),_c('span',{staticClass:"secondary--text"},[_vm._v("*")])]),_c(VAutocomplete,{attrs:{"rounded":"","filled":"","items":_vm.employees,"loading":_vm.loading || _vm.debouncing,"search-input":_vm.search,"no-filter":"","item-value":"employeeNumber","auto-select-first":"","disabled":_vm.disabled,"readonly":_vm.readOnly,"rules":_vm.rules,"clearable":"","dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([(_vm.hint)?{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"grey lighten-1"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,4161362875)},[_vm._v(" "+_vm._s(_vm.hint)+" ")])]},proxy:true}:null,{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getNameAndEmployeeNumber(data.item))+" ")]}},{key:"item",fn:function(data){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.lastName)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(data.item.employeeNumber)+" ")])],1)]}}],null,true),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }