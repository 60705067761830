<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-subheader>
        {{ label }}
        <span v-if="isRequired" class="secondary--text">*</span>
      </v-subheader>
      <v-text-field
        v-model="value"
        rounded
        filled
        readonly
        v-bind="attrs"
        v-on="on"
        :disabled="isDisplayMode"
        :required="isRequired"
        :rules="rules"
        :messages="messages"
        dense
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="grey lighten-1">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ hint }}
          </v-tooltip>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="value"
      :active-picker.sync="activePicker"
      :allowed-dates="allowedDates"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    item: Object,
    field: Object,
    type: String,
    label: String,
    hint: String,
    messages: Array,
    rules: Array,
    isRequired: Boolean,
    isDisplayMode: Boolean,
    allowedDates: Function,
    date: String,
    displayFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    outputFormat: {
      type: String,
      default: ''
    }
  },
  name: 'DateInput',
  data() {
    return {
      menu: false,
      activePicker: null
    }
  },
  computed: {
    value: {
      get() {
        return this.date
          ? this.$moment(this.date).local().format(this.displayFormat)
          : null
      },
      set(v) {
        const localNoon = v
          ? this.$moment(v)
              .hour(12)
              .minute(0)
              .second(0)
              .utc()
              .format(this.outputFormat)
          : null
        this.$emit('update:date', localNoon)
        this.menu = false
      }
    }
  }
}
</script>