<template>
  <v-flex v-if="user">
    <v-flex v-if="type === 'String'">
      <v-subheader>
        {{ label }}
        <span v-if="isRequired" class="secondary--text">*</span>
      </v-subheader>
      <v-text-field
        v-if="maxlength"
        v-model="f.value"
        rounded
        filled
        :rules="rules"
        :required="isRequired"
        color="red"
        :disabled="!isEditable || isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :readonly="isDisplayMode"
        counter
        :maxlength="maxlength"
        dense
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="grey lighten-1">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ hint }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-text-field
        v-else
        v-model="f.value"
        rounded
        filled
        :rules="rules"
        :required="isRequired"
        color="red"
        :disabled="!isEditable || isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :readonly="isDisplayMode"
        dense
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="grey lighten-1">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ hint }}
          </v-tooltip>
        </template>
      </v-text-field>
    </v-flex>
    <v-flex v-else-if="type === 'Email'">
      <v-subheader>
        {{ label }}
        <span v-if="isRequired" class="secondary--text">*</span>
      </v-subheader>
      <v-text-field
        v-model="f.value"
        rounded
        filled
        :rules="emailRules"
        :required="isRequired"
        color="red"
        :disabled="isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :readonly="!isEditable"
        counter
        :maxlength="maxlength"
        dense
        @input="searchForExistingAccount"
        :error-messages="f.errors"
        validate-on-blur
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="grey lighten-1">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ hint }}
          </v-tooltip>
        </template>
      </v-text-field>
    </v-flex>
    <v-alert
      id="alert-existing-account"
      text
      color="error"
      class="pa-0 mx-0"
      v-if="existingAccount && !this.isExistingAccountMatch"
    >
      <v-list
        container
        dense
        light
        flat
        id="list-existing-account-details"
      >
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Username
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.username }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Name
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.lastName }}, {{existingAccount.firstName}}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Company
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.companyName }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Status
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.status }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Account Expiry
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{formatDisplayDate(existingAccount.accountExpiry)}}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Sponsor Employee Number
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.sponsorEmployeeNumber }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Sponsor Organization Code
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text text-wrap">
            {{ existingAccount.sponsorOrgCode }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-alert>
    <v-flex v-else-if="type === 'Boolean'">
      <v-checkbox
        v-model="f.value"
        :label="label"
        :required="isRequired"
        :disabled="!isEditable || isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :rules="rules"
        dense
      ></v-checkbox>
    </v-flex>
    <v-flex v-else-if="type === 'Date'">
      <date-input
        :date.sync="f.value"
        :allowedDates="allowedDates"
        :rules="rules"
        :isDisplayMode="!isEditable || isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :isRequired="isRequired"
        :label="label"
        :hint="hint"
        :messages="dateMessages"
        dense
      ></date-input>
    </v-flex>
    <v-flex v-else-if="type === 'Employee'">
      <sponsor-select
        :selectedSponsor.sync="f.value"
        :disabled="!isEditable || isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :readOnly="isDisplayMode"
        :label="label"
        :hint="hint"
        :rules="rules"
      ></sponsor-select>
    </v-flex>
    <v-flex v-else-if="type === 'Sponsor'">
      <sponsor-select
        :selectedSponsor.sync="f.value"
        :disabled="!isEditable || isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :readOnly="isDisplayMode"
        :label="label"
        :hint="hint"
        :rules="rules"
      ></sponsor-select>
    </v-flex>
    <v-flex v-else-if="type === 'Organization'">
      <sponsor-organization-select
        :selectedOrganization.sync="f.value"
        :label="label"
        :disabled="!isEditable || isDisplayMode || accountStatus === 'DEPROVISIONED'"
        :readOnly="isDisplayMode"
        :hint="hint"
        :rules="rules"
      ></sponsor-organization-select>
    </v-flex>
  </v-flex>
</template>

<script>
import DateInput from '@/components/InputTypes/DateInput.vue'
import { useUserStore } from '@/store/user'
import { useAccountStore } from '@/store/account'
import { mapActions, mapState } from 'pinia'
import SponsorOrganizationSelect from './InputTypes/SponsorOrganizationSelect.vue'
import SponsorSelect from './InputTypes/SponsorSelect.vue'
import FormattingMixin from '@/mixins/formatting.mixin'

export default {
  props: {
    item: Object,
    field: Object,
    type: String,
    label: String,
    hint: String,
    rules: Array,
    isRequired: Boolean,
    isDisplayMode: Boolean,
    isEditable: Boolean,
    maxlength: Number,
    accountStatus: String
  },
  name: 'AccountFormField',
  components: { DateInput, SponsorOrganizationSelect, SponsorSelect },
  mixins: [ FormattingMixin ],
  data() {
    return {
      f: {
        name: null,
        value: null,
        fields: [],
        items: []
      },
      allowedDates: null,
      existingAccount: null,
      isExistingAccountMatch: true
    }
  },
  computed: {
    dateMessages() {
      if (this.f.value > this.$moment().format('YYYY-MM-DD')) {
        return []
      } else {
        return ['Account has expired.']
      }
    },
    emailRules() {
      return [
        (v) => !!v || 'This is a required field.',
        (v) => (!!v && v.length <= 100) || 'Maximum 100 characters.',
        (v) =>
          !v ||
          !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
            v
          ) ||
          'Must be in Latin characters.',
        (v) => 
          !v ||
          // (/(?:[a-z0-9!#$%&*+/=?^_{|}-]+(?:\.[a-z0-9!#$%&*+/=?^_{|}-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]?[a-z0-9])+|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
          //   v
          // ) &&
            (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) &&
          // v.match(/.+?(?=@)/)[0].length <= 60 &&
          // v.match(/(?<=\@).*/)[0].length <= 254) ||
            v.match(/(?<=\@).*/)[0].length <= 254 &&
            v.match(/(?<=\@).*/)[0].length >= 2 &&
            v.match(/.+?(?=@)/)[0].length <= 64) ||
          'Email must be valid.',
        (v) => (!this.existingAccount || this.isExistingAccountMatch) || 'Account with the same email address already exists.'
      ]
    },
    ...mapState(useUserStore, ['user'])
  },
  watch: {
    'f.value': {
      deep: true,
      handler(value) {
        this.$emit('update', {
          name: this.field.name,
          value
        })
      }
    },
    existingAccount: {
      handler(value) {
        if (value) {
          if (this.item.fields['username'] !== value.username) {
            this.f.errors = ['Account with the same email address already exists.']
            this.isExistingAccountMatch = false
          } else {
            this.isExistingAccountMatch = true
            this.f.errors = []
          }
        } else {
          this.f.errors = []
          this.isExistingAccountMatch = true
        }
      }
    }
  },
  methods: {
    async searchForExistingAccount(v) {
      if (v) {
        this.$emit('isValidating', true)
        await this.searchAccountsByEmailAsync(v)
          .then((res) => {
            if (res.data) {
              this.existingAccount = res.data
            } else {
              this.existingAccount = null
            }
            this.$emit('isValidating', false)
          })
          .catch((error) => {
            this.existingAccount = null
            this.$emit('isValidating', false)
          })
      } else {
        this.existingAccount = null
      }
    },
    ...mapActions(useAccountStore, ['searchAccountsByEmailAsync'])
  },
  mounted() {
    this.f.value = this.item.fields[this.field.name]
  }
}
</script>

<style>
.v-subheader {
  padding: 0 22px !important;
}
.v-text-field__details {
  padding: 0 22px !important;
}
</style>