import ApiService from '@/service/api.service.js'
import { defineStore } from 'pinia'

export const useEmployeeStore = defineStore('employee', {
  state: () => ({
    employee: null,
    employees: null
  }),
  getters: {
    getEmployee(state) {
      return state.employee
    },
    getEmployees(state) {
      return state.employees
    }
  },
  actions: {
    async searchEmployeesAsync(q) {
      // let params = `perPage=${options.itemsPerPage}&page=${options.page}&query=${options.query}`
      ApiService.customRequest({
        method: 'get',
        url: `/Search/Employees/${q}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.employees = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchEmployeeAsync(employeeNumber) {
      ApiService.customRequest({
        method: 'get',
        url: `/Employee/${employeeNumber}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.employee = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
})
