<template>
  <v-flex>
    <v-subheader>
      {{ label }}
      <span class="secondary--text">*</span>
    </v-subheader>
    <v-autocomplete
      rounded
      filled
      :items="employees"
      v-model="select"
      :loading="loading || debouncing"
      :search-input.sync="search"
      no-filter
      item-value="employeeNumber"
      auto-select-first
      :disabled="disabled"
      :readonly="readOnly"
      :rules="rules"
      clearable
      dense
    >
      <template v-slot:append v-if="hint">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="grey lighten-1">
              mdi-help-circle-outline
            </v-icon>
          </template>
          {{ hint }}
        </v-tooltip>
      </template>
      <template slot="selection" slot-scope="data">
        {{ getNameAndEmployeeNumber(data.item) }}
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.firstName }} {{ data.item.lastName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.employeeNumber }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-flex>
</template>

<script>
import { useEmployeeStore } from '@/store/employee'
import { mapActions } from 'pinia'
import { mapState } from 'pinia'

export default {
  props: {
    label: { type: String, default: 'Sponsor' },
    multiple: { type: Boolean, default: false },
    selectedSponsor: { type: String },
    disabled: { type: Boolean },
    readOnly: { type: Boolean },
    sponsors: { type: Array },
    hint: { type: String },
    rules: { type: Array }
  },
  data() {
    return {
      select: null,
      options: {
        page: 1,
        itemsPerPage: 1,
        query: null
      },
      search: '',
      loading: false,
      debouncing: false,
      debounceTimeout: null,
      sponsorRules: []
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    },
    ...mapState(useEmployeeStore, ['employee', 'employees'])
  },
  methods: {
    getNameAndEmployeeNumber(item) {
      return `${item.firstName} ${item.lastName} (${_.trim(
        item.employeeNumber
      )})`
    },
    async updateSelectedSponsor() {
      if (!_.isEmpty(this.selectedSponsor)) {
        this.search = this.selectedSponsor
        this.select = this.selectedSponsor
        this.options.query = this.search
        await this.searchEmployeesAsync(this.search).then((res) => {})
      }
    },
    async querySelections(val) {
      this.loading = true
      let requestType = this.requestType
      if (this.disabled) {
        requestType = ''
      }
      await this.searchEmployeesAsync(val, requestType)
        .then((res) => {
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    ...mapActions(useEmployeeStore, ['searchEmployeesAsync'])
  },
  watch: {
    async selectedSponsor() {
      await this.updateSelectedSponsor()
    },
    search(val) {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debouncing = false
          this.querySelections(val)
        }, 300)
      }
    },
    select(o) {
      if (o !== null && o !== undefined) {
        this.$emit('update:keyword', o)
        this.$emit('update:selectedSponsor', o)
        this.$emit('updateSelectedSponsorEmployeeNumber', o.employeeNumber)
      } else {
        this.$emit('update:keyword', null)
        this.$emit('update:selectedSponsor', null)
        this.$emit('updateSelectedSponsorEmployeeNumber', null)
      }
    }
  },
  async mounted() {
    await this.updateSelectedSponsor()
    // console.log('sponsor select')
    // console.log(this.selectedSponsorOrgManagerEmployeeNumber)
    // if (this.$router.history.current.name !== 'show-account') {
    //   this.additionalRules = [
    //     (v) => this.select !== this.selectedSponsorOrgEmployeeNumber || 'Sponsor and sponsoring organization manager cannot be the same.'
    //   ]
    //   if (this.rules !== undefined) {
    //     this.sponsorRules = this.rules.concat(this.additionalRules)
    //   }
    // }
  }
}
</script>